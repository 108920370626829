import { Box, Typography, IconButton } from "@mui/material";
import CircleRoundedIcon from "@mui/icons-material/CircleRounded";
import TextWithCopyIcon from "../../../Helper/Operations/TextWithCopyIcon";
import { getColumnTitleSort } from "../../../Features/Table/AntDesign/TableFunctions";
import { convertUTCtoZone, getDatePickerDate } from "../../../Helper/DatePicker/DateConverter";
import TextHyperLinkWithCopyIcon from "../../../Helper/Operations/TextHyperLinkWithCopyIcon";
import { useLocation, useNavigate } from "react-router-dom";
import CustomIcon from "../../../Components/CustomIcon";
import {
  encryptTheParams,
  decryptTheParams,
} from "../../../Helper/QueryParams/EncryptDecrypt";
import moment from "moment";
import Cookies from "universal-cookie";


export const GetAlertsColumns = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getFormattedResolutionTime = (activeDuration) => {

    const durations = [
      { label: 'Year', value: 60 * 24 * 365 },
      { label: 'Month', value: 60 * 24 * 30 },
      { label: 'Day', value: 60 * 24 },
      { label: 'Hour', value: 60 },
      { label: 'Minute', value: 1 }
    ];

    let remainingMinutes = activeDuration;
    const parts = [];

    durations.forEach(duration => {
      const durationValue = Math.floor(remainingMinutes / duration.value);
      if (durationValue > 0) {
        parts.push(`${durationValue} ${duration.label}${durationValue > 1 ? 's' : ''}`);
        remainingMinutes %= duration.value;
      }
    });

    return parts.join(', ');
  }

  function onBatteryIDClick(record) {
    const cookies = new Cookies()
    const queryParams = decryptTheParams();
    let temp;
    temp = {
      ...queryParams,
      batteryID: record.batteryID,
      deviceID: record?.deviceID,
      batterySearchedGlobal: "false",
      selectedHierarchy: "battery",
      hierarchyLevel: "battery",
      startDateSB: record.startTime.substring(0, 10),
      endDateSB: getDatePickerDate(),
      oldTimeZone: cookies.get('timeZone'),
      sidebarTabValue: "alertTab",
      prevLink: location.pathname + location.search,
      navigateFromTab: true,
    };

    const expandAccordionTabForStatus = ['active', 'open']
    if (expandAccordionTabForStatus. includes(record.alertStatus?.toLowerCase())) {
      temp = {
        ...temp,
        expandedAccordionName: 'alertGroup' + record?.alertDescription?.toLowerCase()?.replace(/ /g, "-"),
        redirectedFromAlertPage: "true"
      }
    }

    encryptTheParams(temp, navigate, true);
  }

  const GetColumns = (tab) => {
    const cols = alertColumns.filter((x) => x.tabs?.includes(tab));
    return cols;
  };

  const alertColumns = [
    {
      dataIndex: "pingStatus",
      key: "pingStatus",
      fixed: "left",
      defaultSortOrder: "",
      showSorterTooltip: false,
      filterMode: "tree",
      filterSearch: false,
      uniqueKey: "pingStatusLiOpen",
      align: "left",
      width: 40,
      render: (pingStatus) => (
        <CustomIcon
          name={pingStatus === "Active" ? "online" : "offline"}
          style={{
            width: "24px"
          }}
        />
      ),
      tabs: ["Active", "Open", "Resolved", "Expired"],
    },
    {
      title: <Typography variant="tableHeadBold">Device ID</Typography>,
      name: "Device ID",
      fixed: "left",
      sorter: false,
      uniqueKey: "deviceIDActive",
      filterSearch: false,
      width: 170,
      className: "undragableColumn",
      render: (record) => (
        <div className="renderID">
          {TextHyperLinkWithCopyIcon(
            record?.batteryID,
            "Device ID",
            onBatteryIDClick,
            record
          )}
        </div>
      ),
      tabs: ["Active", "Open", "Resolved", "Expired"],
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "lastPingedTime", "Last Pinged"),
      name: "Last Pinged",

      dataIndex: "lastPingedTime",
      key: "lastPingedTime",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "",
      showSorterTooltip: false,
      filterMode: "tree",
      filterSearch: false,
      uniqueKey: "lastPingedTimeActive",
      width: 170,
      render: (lastPingedTime) => (
        <Typography variant="tableRow">
          {convertUTCtoZone(lastPingedTime)}
        </Typography>
      ),
      tabs: ["Active", "Open", "Resolved", "Expired"],
    },
    {
      title: <Typography variant="tableHeadBold">Packs Affected</Typography>,
      name: "Packs Affected",
      dataIndex: "packCount",
      sorter: false,
      showSorterTooltip: false,
      checkedOptions: [],
      width: 120,
      uniqueKey: "packAffectedActive",
      render: (packCount) => (
        <Typography variant="tableRow">{packCount}</Typography>
      ),
      tabs: ["Active", "Open", "Resolved", "Expired"],
    },
    {
      title: <Typography variant="tableHeadBold">Modules Affected</Typography>,
      name: "Modules Affected",
      dataIndex: "moduleCount",
      sorter: false,
      showSorterTooltip: false,
      checkedOptions: [],
      width: 140,
      uniqueKey: "moduleAffectedActive",
      render: (moduleCount) => (
        <Typography variant="tableRow">{moduleCount}</Typography>
      ),
      tabs: ["Active", "Open", "Resolved", "Expired"],
    },
    {
      title: <Typography variant="tableHeadBold">Cells Affected</Typography>,
      name: "Cells Affected",
      dataIndex: "cellCount",
      sorter: false,
      showSorterTooltip: false,
      checkedOptions: [],
      width: 120,
      uniqueKey: "cellAffectedActive",
      render: (cellCount) => (
        <Typography variant="tableRow">{cellCount}</Typography>
      ),
      tabs: ["Active", "Open", "Resolved", "Expired"],
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "startTime", "Alert First Seen"),
      name: "Alert First Seen",

      dataIndex: "startTime",
      key: "startTime",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "",
      showSorterTooltip: false,
      filterMode: "tree",
      filterSearch: false,
      uniqueKey: "startTimeActive",
      width: 170,
      render: (startTime) => (
        <Typography variant="tableRow">
          {convertUTCtoZone(startTime)}
        </Typography>
      ),
      tabs: ["Active", "Open", "Resolved", "Expired"],
    },
    {
      title: <Typography variant="tableHeadBold">Action Taken By</Typography>,
      name: "Action Taken By",
      dataIndex: "openedBy",
      key: "openedBy",
      sorter: false,
      showSorterTooltip: false,
      checkedOptions: [],
      width: 170,
      uniqueKey: "openedByActive",
      render: (openedBy) => (
        <Typography variant="tableRow">{openedBy}</Typography>
      ),
      tabs: ["Open"],
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "openedAt", "Action Taken At"),
      name: "Action Taken At",
      dataIndex: "openedAt",
      key: "openedAt",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "",
      showSorterTooltip: false,
      filterMode: "tree",
      filterSearch: false,
      uniqueKey: "openedAtActive",
      width: 170,
      render: (openedAt) => (
        <Typography variant="tableRow">
          {convertUTCtoZone(openedAt)}
        </Typography>
      ),
      tabs: ["Open"],
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "statusUpdateTime", "Resolved At"),
      name: "Resolved At",
      dataIndex: "statusUpdateTime",
      key: "statusUpdateTime",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "",
      showSorterTooltip: false,
      filterMode: "tree",
      filterSearch: false,
      uniqueKey: "statusUpdateTimeActive",
      width: 170,
      render: (statusUpdateTime) => (
        <Typography variant="tableRow">
          {convertUTCtoZone(statusUpdateTime)}
        </Typography>
      ),
      tabs: ["Resolved"],
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "statusUpdateTime", "Expired At"),
      name: "Expired At",
      dataIndex: "statusUpdateTime",
      key: "statusUpdateTime",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "",
      showSorterTooltip: false,
      filterMode: "tree",
      filterSearch: false,
      uniqueKey: "statusUpdateTimeActive",
      width: 170,
      render: (statusUpdateTime) => (
        <Typography variant="tableRow">
          {convertUTCtoZone(statusUpdateTime)}
        </Typography>
      ),
      tabs: ["Expired"],
    },
    {
      title: <Typography variant="tableHeadBold">Resolution Time</Typography>,
      name: "Resolution Time",
      dataIndex: "activeDuration",
      key: "activeDuration",
      sorter: false,
      showSorterTooltip: false,
      checkedOptions: [],
      width: 170,
      uniqueKey: "activeDurationActive",
      render: (activeDuration) => (
        <Typography variant="tableRow">{getFormattedResolutionTime(activeDuration)}</Typography>
      ),
      tabs: ["Resolved"],
    },
    {
      title: <Typography variant="tableHeadBold">Severity</Typography>,
      name: "Severity-Latest",
      dataIndex: "severityName",
      key: "severityName",
      sorter: false,
      showSorterTooltip: false,
      checkedOptions: [],
      width: 170,
      uniqueKey: "severityNameActive",
      render: (severityName) => (
        <Typography variant="tableRow">{severityName}</Typography>
      ),
      tabs: ["Active", "Open"],
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "socLive", "SOC (%)", "Latest Recorded"),
      name: "Latest SOC (%)",
      dataIndex: "socLive",
      key: "socLive",
      align: "left",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "",
      showSorterTooltip: false,
      checkedOptions: [],
      width: 150,
      uniqueKey: "socLiveActive",
      render: (socLive) => (
        <Typography variant="tableRow">{socLive}</Typography>
      ),
      tabs: ["Active", "Open"],
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(titleProps, "voltageLive", "Voltage (V)", "Latest Recorded"),
      name: "Latest Volt (V)",
      dataIndex: "voltageLive",
      key: "voltageLive",

      align: "left",

      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "",
      showSorterTooltip: false,
      checkedOptions: [],
      width: 170,
      uniqueKey: "voltageLiveActive",
      render: (voltageLive) => (
        <Typography variant="tableRow">
          {voltageLive?.toFixed(1)}
        </Typography>
      ),
      tabs: ["Active", "Open"],
    },
    {
      title: (titleProps) =>
        getColumnTitleSort(
          titleProps,
          "temperatureLive",
          "Temperature (°C)",
          "Latest Recorded"
        ),
      name: "Latest Temperature (°C)",
      dataIndex: "temperatureLive",
      key: "temperatureLive",

      align: "left",

      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      defaultSortOrder: "",
      showSorterTooltip: false,
      checkedOptions: [],
      width: 160,
      uniqueKey: "temperatureLiveActive",
      render: (temperatureLive) => (
        <Typography variant="tableRow">{temperatureLive}</Typography>
      ),
      tabs: ["Active", "Open"],
    },
    {
      title: <Box>
        <Typography variant="tableHeadBold">Location</Typography>
        <Typography
          style={{
            fontSize: "11px",
            color: "#828A92"
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {"Latest Recorded"}
        </Typography>
      </Box>,
      name: "Location-Latest",
      dataIndex: "locationLive",
      key: "locationLive",
      sorter: false,
      showSorterTooltip: false,
      checkedOptions: [],
      width: 170,
      uniqueKey: "locationLiveActive",
      render: (locationLive) => (
        <Typography variant="tableRow">{locationLive}</Typography>

      ),
      tabs: ["Active", "Open"],
    },
  ];

  const columns1 = [
    {
      name: "Active Columns",
      columns: GetColumns("Active"),
    },
    {
      name: "Open Columns",
      columns: GetColumns("Open"),
    },
    {
      name: "Resolved",
      columns: GetColumns("Resolved"),
    },
    {
      name: "Expired",
      columns: GetColumns("Expired"),
    },
  ];

  return columns1;
};
