import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import { formatDate } from "../../Helper/DatePicker/DateFormatters";
import { DegradationChartContainer, DegradationChartNoDataContainer, DegradationChartNoDataText, DegradationChartNoDataTextBox } from "../../PagesContent/BatteryHealth/Components/StyledComponent";
import CODE from "../../Static/Constants/StatusCodes";

// Helper functions
const closestMultiple = (n, x) => (x > n ? x : Math.ceil(n / x) * x);
const previousMultiple = (n, x) => (x > n ? x : Math.floor(n / x) * x);

const calculateBounds = (data, xmax, ymin) => {
  data.forEach(item => {
    xmax = Math.max(xmax, item.x || 0);
    ymin = Math.min(ymin, item.y !== undefined ? item.y : ymin);
  });
  return { xmax, ymin };
};

const formatTooltip = ({ seriesIndex, dataPointIndex, w }, cycleAge) => {
  const data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
  const presentCapacity = Number.isFinite(data.presentCapacity) ? data.presentCapacity.toFixed(1) : 'NA';
  const ageInMonths = cycleAge ? data.secondaryX : data.x;
  const eqCycles = cycleAge ? data.x : data.secondaryX;
  const newDate = data.date ? formatDate(new Date(data.date)) : "Unknown";

  if (!Number.isFinite(data.y)) {
    return "";
  }

  return `
    <div class="tooltip_container">
      <span class="flex_center">
        <span class="text_container">SOH (%) <b>: ${data.y?.toFixed(1)}</b></span>
      </span>
      <hr class="horizontal_rule"></hr>
      <span class="time_container">Present Capacity (Ah) <b>: ${presentCapacity}</b></span>
      <br><br>
      <span class="time_container">Age (months) <b>: ${ageInMonths?.toFixed(1)}</b></span>
      <br><br>
      <span class="time_container">Eq. Cycle <b>: ${eqCycles?.toFixed(1)}</b></span>
      <br><br>
      <span class="time_container">Date <b>: ${newDate}</b></span>
    </div>
  `;
};

const generateSeriesForChart = (xMax, yMin, dataSeries1, dataSeries2, statusCode) => {
  if (statusCode !== CODE.SUCCESS) {
    return [];
  }
  return [
    {
      type: "line",
      name: "SOH (%)",
      data: [...dataSeries1, ...dataSeries2],
    },
    {
      type: "rangeArea",
      name: "Warranty Line",
      data: [
        { x: 0, y: [yMin, 70] },
        { x: xMax, y: [yMin, 70] },
      ],
    },
  ];
};

const getYAxisSetting = (yTitle, yTitleColor, yMin) => {
  return {
    min: yMin,
    max: 120,
    tickAmount: 6,
    axisBorder: { show: true, color: "#808080", offsetX: -1.5 },
    labels: {
      style: { colors: "#1C1C1C", fontSize: ThemeProperties.c_axis_labels, fontFamily: "Roboto", fontWeight: ThemeProperties.c_axis_labels_weight },
      formatter: value => Math.round(value),
    },
    title: {
      text: yTitle,
      offsetX: -2,
      style: { color: yTitleColor, fontSize: ThemeProperties.c_axis_title, fontFamily: "Roboto", fontWeight: ThemeProperties.c_axis_title_weight },
    },
  }
}

const getXAxisSetting = (xTitle, xMax) => {
  return {
    type: "numeric",
    min: 0,
    max: xMax,
    tickAmount: 5,
    axisBorder: { color: "#808080" },
    labels: {
      rotate: 0,
      style: { colors: "#1C1C1C", fontSize: ThemeProperties.c_axis_labels, fontFamily: "Roboto", fontWeight: ThemeProperties.c_axis_labels_weight },
      formatter: value => value?.toFixed(0),
    },
    title: {
      text: xTitle,
      offsetX: 3,
      offsetY: -5,
      style: { color: ThemeProperties.black, fontSize: ThemeProperties.c_axis_title, fontFamily: "Roboto", fontWeight: ThemeProperties.c_axis_title_weight },
    },
    axisTicks: { color: "#828A92" },
    tooltip: {
      enabled: false
    }
  }
}

const generateOptionsForChart = (xMax, yMin, statusCode, yTitle, yTitleColor, xTitle, cycleAge, rulData) => {
  const isChartEmpty = statusCode !== CODE.SUCCESS;
  return {
    chart: {
      zoom: { enabled: !isChartEmpty, minZoom: 10 },
      animations: { enabled: false },
      events: {
        beforeZoom: (chart, { xaxis }) => ({
          xaxis: { min: xaxis.min, max: xaxis.max / 30 },
        }),
        beforeResetZoom: () => ({ xaxis: { min: 0, max: xMax, tickAmount: 5 } }),
      },
    },
    colors: [yTitleColor, "#E9E9E9"],
    dataLabels: { enabled: false },
    fill: { opacity: [1, 1] },
    forecastDataPoints: { count: rulData?.length || 0 },
    stroke: { curve: "straight", width: [3, 0] },
    markers: { size: 0, hover: { size: 5, sizeOffset: 1 } },
    legend: { show: false },
    yaxis: getYAxisSetting(yTitle, yTitleColor, yMin),
    xaxis: getXAxisSetting(xTitle, xMax),
    tooltip: {
      enabled: !isChartEmpty,
      intersect: false,
      followCursor: true,
      custom: tooltipData => formatTooltip(tooltipData, cycleAge),
    },
  };
};

const mountTheChart = (xMax, yMin, dataSeries1, dataSeries2, statusCode, yTitle, yTitleColor, xTitle, cycleAge, setChartState) => {
  const newYMin = yMin > 60 ? 60 : yMin;
  const series = generateSeriesForChart(xMax, newYMin, dataSeries1, dataSeries2, statusCode);
  const options = generateOptionsForChart(xMax, newYMin, statusCode, yTitle, yTitleColor, xTitle, cycleAge, dataSeries2);
  setChartState({ series, options });
};

// Main component
const BatteryHealthApexAreaChart = ({ data, xTitle, yTitle, cycleAge, yTitleColor }) => {
  const [chartState, setChartState] = useState({ series: [], options: {} });

  useEffect(() => {
    const statusCode = data?.responseStatus?.code;
    if (statusCode === CODE.SUCCESS) {
      const { xmax: newXmax, ymin: newYmin } = calculateBounds(data.sohData, 0, 100);
      const { xmax: newXmax2, ymin: newYmin2 } = calculateBounds(data.rulData, newXmax, newYmin);
      const newDegradationData = data.sohData.map(item => ({
        date: item.date || 0,
        secondaryX: item.secondaryX || 0,
        x: item.x || 0,
        y: item.y || 0,
        presentCapacity: item.presentCapacity,
      }));

      mountTheChart(
        closestMultiple(Math.ceil(newXmax2), 5),
        previousMultiple(Math.floor(newYmin2), 5),
        newDegradationData,
        data.rulData,
        statusCode,
        yTitle,
        yTitleColor,
        xTitle,
        cycleAge,
        setChartState
      );
    } else {
      mountTheChart(5, 0, [], [], statusCode, yTitle, yTitleColor, xTitle, cycleAge, setChartState);
    }
  }, [data, yTitle, yTitleColor, xTitle, cycleAge]);

  return (
    <DegradationChartContainer>
      {data?.responseStatus?.code !== CODE.SUCCESS && (
        <DegradationChartNoDataContainer>
          <DegradationChartNoDataTextBox>
            <DegradationChartNoDataText variant="textSubtitle">
              SOHc for this battery is not computed
            </DegradationChartNoDataText>
          </DegradationChartNoDataTextBox>
        </DegradationChartNoDataContainer>
      )}
      <Chart options={chartState.options} series={chartState.series} type={"rangeArea"} height="100%" width="100%" />
    </DegradationChartContainer>
  );
};

export default BatteryHealthApexAreaChart;
