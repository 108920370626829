import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { updateQueryParams } from "../../Helper/QueryParams/EncryptDecrypt";
import { SetBatteryHealthCycleAge } from "../../Actions/BatteryHealth";
import { FormGroup } from "@mui/material"
import Loader from "../../Helper/Loaders/Loader"
import { EqCycleSwitchBox, ShowEqCycleText, SOHChartContainer } from "./Components/StyledComponent";
import Events from "../../Analytics/Events";
import CODE from "../../Static/Constants/StatusCodes";
import ApexScatterPlusLine from "../../Charts/Mixed/ApexScatterPlusLine";
import { getMarkerColorForSOHChart } from "../../Helper/BatteryHealth/BatteryHealthHelper";
import { ThemeProperties } from "../../Theme/ThemeProperties";
import CustomSwitch from "../../Components/CustomSwitch";
import CardWithHeader from "../../Features/Cards/CardWithHeader";
import { useNotification } from "../../Hooks/useNotification";
import CustomIcon from "../../Components/CustomIcon"

const SOHScatterChartSection = ({
  batteriesData
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { openNotification, closeNotification } = useNotification();
  const [csvData, setCsvData] = useState({
    data: [],
    responseStatus: { code: null }
  })
  const cycleAge = useSelector((state) => state.BatteryHealthCycleAge.value)

  const generateCsvData = () => {
    setCsvData({
      data: [],
      responseStatus: { code: CODE.LOADING }
    })
    let data = []
    batteriesData.data.forEach((item) => {
      data.push({
        "Serial Number": item.deviceID,
        "Age (months)": item.age !== null ? (item.age / 30).toFixed(2) : "",
        "Eq. cycle": item.eqCycleEst?.toFixed(2),
        "SOH (%)": item.sohEst?.toFixed(2),
        "Initial Capacity (Ah)": item.initialCapacity?.toFixed(2),
        "Present Capacity (Ah)": item.presentCapacity?.toFixed(2)
      })
    })
    const structuredCsvData = {
      data: data,
      responseStatus: {
        code: CODE.SUCCESS
      }
    }
    setCsvData(structuredCsvData)
  }
  
  const handleToggleCycleAge = (checked) => {
    Events("Battery Health: Toggled Show Eq. Cycle Fleet battery Health")
    dispatch(SetBatteryHealthCycleAge(checked))

    updateQueryParams(navigate, {
      cycleAge: checked
    })
  }

  return (
    <CardWithHeader
      onMouseEnter={() => Events("Battery Health: Hover on State of Health (SOH) of all batteries in the fleet")}
      title="State of Health (SOH) of all batteries in the fleet"
      subtitle="Graph shows the present SOH of every battery in the fleet."
      iconElement={<CustomIcon name="healthWithBattery"></CustomIcon>}
      pageLabel="Battery Health"
      showCSVDownload={true}
      csvData={csvData}
      csvName={"BatteriesHealth"}
      openNotification={openNotification}
      closeNotification={closeNotification}
      generateCsvData={generateCsvData}
    >
      <SOHChartContainer>
        <FormGroup>
          <EqCycleSwitchBox
            control={
              <CustomSwitch
                checked={cycleAge}
                onChange={handleToggleCycleAge}
                inputProps={{ "aria-label": "controlled" }}
                size="small"
              />
            }
            label={
              <ShowEqCycleText>
                Show Eq. Cycle
              </ShowEqCycleText>
            }
          />
        </FormGroup>
        {[CODE.SUCCESS, CODE.NODATA].includes(batteriesData?.responseStatus?.code) ? (
          <ApexScatterPlusLine
            line={[]}
            scatter={batteriesData.scatterChartData}
            batteryID={batteriesData.batteriesIds}
            xmax={batteriesData.xMax}
            xmin={batteriesData.xMin}
            ymax={batteriesData.yMax}
            ymin={batteriesData.yMin}
            xAxisTitle={cycleAge ? "Eq. Cycle" : "Age (months)"}
            xAxisLabelColor={"#1C1C1C"}
            cycleAge={cycleAge}
            getMarkerColor={getMarkerColorForSOHChart}
            navigate={navigate}
          />
        ) : (
          <Loader />
        )}
      </SOHChartContainer>
    </CardWithHeader>
  )
}

export default SOHScatterChartSection
